




































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import _ from "lodash";
import SearchButton from "@/components/Button/SearchButton.vue";
import FilterAndSearch from "@/components/FilterAndSearch.vue";
import { BTable } from "bootstrap-vue";
import i18n from "@/i18n";
import UserService from "@/services/UserService";
import axios from "@/utils/axios";
import { AxiosError, AxiosResponse } from "axios";
import { namespace } from "vuex-class";

@Component({
  components: { FilterAndSearch, SearchButton },
})
export default class FscTable extends Vue {
  public name = "Table";

  @Prop({ default: () => [] })
  public items!: Array<any>;

  @Prop({ default: () => [] })
  public fields!: Array<any>;

  @Prop({ default: () => 1 })
  public totalRows!: number;

  @Prop({ default: () => false, type: Boolean })
  public clientSide!: boolean;

  @Prop({ default: () => false, type: Boolean })
  public hideSearch!: boolean;

  @Prop({ default: () => false, type: Boolean })
  public hidePerPage!: boolean;

  @Prop({ default: () => false, type: Boolean })
  public loading!: boolean;

  @Prop({ default: () => "", type: String })
  public detailsTdClass!: string;

  @Prop({ default: () => "", type: String })
  public showEmpty!: string;

  @Prop({ default: () => false, type: Boolean })
  public hidePagination!: boolean;

  @Prop()
  rowIndex!: number;

  @Prop({ default: () => null, type: String })
  public customFilter!: string | null;

  @Prop({ default: () => true, type: Boolean })
  public selectable!: boolean;

  @Prop({ default: () => false, type: [Boolean, String] })
  public stickyHeader!: any;

  @Prop({ default: () => true, type: Boolean })
  public hover!: boolean;

  @Prop({ default: () => false, type: Boolean })
  public hideFilterIcon!: boolean;

  @Prop({ default: () => false, type: Boolean })
  public filterActiveIcon!: boolean;

  @Prop({ default: () => "", type: String })
  public tableWrapperFilterClass!: string;

  @Prop({ default: () => "", type: String })
  public headerWrapperClass!: string;

  @Prop({ default: () => "", type: String })
  public id!: string;

  @Prop({ default: () => false, type: Boolean })
  public filterFocus!: boolean;

  @Prop({ default: () => false, type: Boolean })
  public sortIconLeft!: boolean;

  @Prop({ default: () => "", type: String })
  public theadClass!: string;

  @Prop({ default: () => i18n.t("general.no_results_found"), type: String })
  public emptyText!: string;

  @Prop({ default: () => "There are no records matching your request", type: String })
  public emptyFilteredText!: string;

  @Prop({ default: () => true, type: Boolean })
  public showCount!: boolean;

  @Prop({ default: () => 5, type: Number })
  public paginationLimit!: boolean;

  @Prop({ default: () => true, type: Boolean })
  public autoFocus!: boolean;

  @Prop({ default: () => false, type: Boolean })
  public small!: boolean;

  @Prop({ default: () => "" })
  public tBodyTrClass!: any;

  @Prop({ default: () => "" })
  public initialOrderBy!: string;

  @Prop({ default: () => "" })
  public initialOrderByDirection!: string;

  @Prop({ default: () => null })
  public initialSearchTerm!: string | null;

  public currentPage = 1;
  public currentPerPage = 25;
  public pageOptions = [1, 2, 10, 25, 50, 100];
  public sortBy = "";
  public sortDesc = false;
  public sortDirection = "asc";
  public currentSearch: null | string = null;
  public filterOn = [];
  public currentOrderBy = "";
  public currentOrderByDirection = "";
  public debouncer: any;
  public total = 0;
  private selectedRowIndex: any;
  private tableRowIndex: any;
  private pageChanged: any;
  private pageOptionsChanged = false;
  protected selectedResources: Array<any> = [];

  public created(): void {
    this.currentPerPage = this.userSettingsFromStorage?.paginationPreference || 25;
    this.currentOrderBy = this.initialOrderBy;
    this.currentOrderByDirection = this.initialOrderByDirection;
    this.currentSearch = this.initialSearchTerm;

    this.$watch(
      () => [this.currentSearch, this.currentPage, this.currentPerPage, this.currentOrderBy, this.currentOrderByDirection],
      () => {
        if (this.clientSide) return;
        this.getResources();
      }
    );

    this.debouncer = _.debounce((callback: any) => callback(), 500);

    this.getResources();
  }

  private get userSettingsFromStorage() {
    return UserService.getUserSettings();
  }

  public updated(): void {
    this.$nextTick(function () {
      let rows = this.$el.getElementsByTagName("tr");

      if (this.selectedRowIndex !== undefined && rows[this.tableRowIndex]) {
        // If the selected element is not active, then click it.
        if (!rows[this.tableRowIndex].classList.contains("table-active") && !this.pageChanged && !this.pageOptionsChanged) {
          // This is executed only when table is regenerated.
          rows[this.tableRowIndex].click();
        }
        // if it hasn't an index row select first
      } else if (this.selectedRowIndex !== undefined && rows[1]) {
        rows[1].click();
      }
    });
  }

  public onRowClicked(context: any, indexInTable: any, event: any): void {
    this.pageChanged = false;
    this.pageOptionsChanged = false;
    this.selectedRowIndex = indexInTable;
    this.tableRowIndex = indexInTable + 1;
    this.$emit("row-clicked", context, indexInTable, event);
  }

  public onRowDblClicked(context: any): void {
    this.$emit("row-dblclicked", context);
  }

  public onFilterClicked(): void {
    this.$emit("filter-clicked");
  }

  public onSortChanged(context: any): void {
    this.currentOrderBy = context.sortBy;
    this.currentOrderByDirection = context.sortDesc ? "desc" : "asc";
  }

  /**
   * Build the resource request query string.
   */
  public get resourceRequestQueryString(): Record<any, any> {
    const perPage = Number(this.currentPerPage);
    const queryBuilder = {
      searchTerm: this.currentSearch,
      limit: perPage,
      offset: this.offset(Number(this.currentPage), perPage),
    };

    if (this.currentOrderBy && this.currentOrderByDirection) {
      Object.assign(queryBuilder, {
        propertySortList: [
          {
            propertyName: this.currentOrderBy,
            direction: this.currentOrderByDirection,
          },
        ],
      });
    }

    if (this.currentSearch) {
      Object.assign(queryBuilder, {
        searchTerm: this.currentSearch,
      });
    }

    return queryBuilder;
  }

  /**
   * Get the resources based on the current page, search, filters, etc.
   */
  public getResources(): void {
    this.$nextTick(() => {
      this.$emit("request-query", this.resourceRequestQueryString);
    });
  }

  public offset(page: number, perPage: number): number {
    return page - 1; // (page - 1) * perPage
  }

  public onSearch(value: string): void {
    this.debouncer(() => {
      this.currentSearch = value;
      this.$emit("search-event", value);
    });
  }

  /**
   * Return the resource count label
   */
  public get resourceCountLabel(): string | number {
    const first = this.currentPerPage * (this.currentPage - 1);

    let last: number;

    if (this.clientSide) {
      last = Math.min(this.total, this.currentPage * this.currentPerPage);
    } else {
      last = first + this.items.length;
    }

    return this.items.length && `${first + 1}-${last}` + " " + this.$t("general.entries_of") + " " + `${this.total}` + " " + this.$t("general.entries");
  }

  public get page(): number {
    if (!this.clientSide) return 1;

    return this.currentPage;
  }

  public onFiltered(filteredItems: any): void {
    if (!this.clientSide) return;
    this.total = filteredItems.length;
    this.currentPage = 1;
  }

  public get filtered(): string | null {
    if (!this.clientSide) return this.customFilter;
    return this.currentSearch;
  }

  public onTablePaginateClick() {
    this.pageChanged = true;
  }

  public onCurrentPerPageChange(perPage: number) {
    this.changePerPageBackend(perPage);
    this.pageOptionsChanged = true;
  }

  @Watch("totalRows", { deep: true, immediate: true })
  public totalRowsWatcher(val: any): void {
    this.total = val;
  }

  /**
   * Select all of the available items
   */
  protected selectAllResources() {
    this.selectedResources = this.items.slice(0);
  }

  /*
   * Update the resource selection status
   */
  protected updateSelectionStatus(resource: any) {
    if (!_(this.selectedResources).includes(resource)) return this.selectedResources.push(resource);
    const index = this.selectedResources.indexOf(resource);
    if (index > -1) return this.selectedResources.splice(index, 1);
  }

  protected get selectAllChecked() {
    return this.items.length && this.selectedResources.length == this.items.length;
  }

  /**
   * Get the IDs for the selected resources.
   */
  protected selectedResourceIds() {
    return _.map(this.selectedResources, (resource) => resource.id.value);
  }

  /**
   * Toggle the selection of all resources
   */
  protected toggleSelectAll() {
    if (this.selectAllChecked) return this.clearResourceSelections();
    this.selectAllResources();
  }

  /**
   * Clear the selected resources and the "select all" states.
   */
  protected clearResourceSelections() {
    this.selectedResources = [];
  }

  @Watch("selectedResources")
  protected onChangeSelectedResources(): void {
    this.$emit("selected-resources", this.selectedResources);
  }

  protected rowSelected(rows: any): void {
    this.$emit("row-selected", rows);
  }

  @Watch("items")
  private onChangeItems(): void {
    this.clearResourceSelections();
  }

  private async changePerPageBackend(perPage: number) {
    const instructorId = UserService.getUser()?.id;
    if (instructorId) {
      await axios
        .put(`/user-settings/${instructorId}?paginationPreference=${perPage}`)
        .then((response: AxiosResponse) => {
          let user = this.userSettingsFromStorage;

          if (!user) {
            user = {
              id: 0,
              personId: instructorId,
              paginationPreference: perPage,
            };
          }
          UserService.setUserSettings({
            ...user,
            paginationPreference: perPage,
          });
        })
        .catch((error: AxiosError) => {
          console.log(error.response?.data);
        });
    }
  }
}
